import React from 'react'
import { 
  ListItem, 
  ContentItem,
  ContentDescription, 
  Button, 
  ButtonText, 
  ChevronRight
} from './styles'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'

const propTypes = {
  caseStudies: PropTypes.array
}

const defaultProps = {
  caseStudies: []
}

const CaseStudyListCard = ({ caseStudies }) => (
  <ListItem>
    {
      caseStudies.map((caseStudy) => (
        <ContentItem
          key={ caseStudy.title }
          style={{ display: 'flex' }}
        >
          <img 
            src={ caseStudy.thumbnail_photo } 
            alt={ `thumbnail ${caseStudy.title}` }
          />
          <ContentDescription>

            <Link to={ `/case-studies/${caseStudy.slug}` }>
              { caseStudy.title }
            </Link>
            { /* currently, this section is hiding because we have'nt handle on crm */ }
            { /* <TypeText>
              Face Recognition
            </TypeText> */ }
            <Link to={ `/case-studies/${caseStudy.slug}` }>
              <Button>
                <ButtonText>
                  <FormattedMessage id='c323a43cd' />
                </ButtonText>
                <ChevronRight />
              </Button>
            </Link>
          </ContentDescription>
        </ContentItem>
      ))
    }
  </ListItem>
)

CaseStudyListCard.propTypes = propTypes
CaseStudyListCard.defaultProps = defaultProps

export default CaseStudyListCard
