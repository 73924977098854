import React, { useState, useEffect, useCallback } from 'react'
import { 
  Layout, 
  SEO 
} from 'components'
import { 
  CaseStudyListFooter, 
  CaseStudyListCard 
} from './components'
import { 
  Header,
  HeaderImg,
  Title 
} from './styles'
import { FormattedMessage } from 'react-intl'
import Img from './media/header.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import API, { BlogURL } from 'commons/API'

const DESC = 'Read our thoughts about the technology implementations in industry.'

const CaseStudyList = ({ pageContext: { caseStudies } }) => {
  
  const [caseStudyList, setCaseStudyList] = useState(caseStudies)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(2)

  const fetchNews = useCallback((page) => {
    API.get(`${BlogURL.GetBlog}?page=${page}&per_page=6&category=case-study`)
      .then((res) => {
        const data = res.data.contents
        const totalPage = res.data.meta.total_page
        setTotalPage(totalPage)

        if(page === 1) {
          setCaseStudyList(data)
        }
        else {
          setCaseStudyList([...caseStudyList, ...data])
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    fetchNews(page)
  }, [fetchNews, page])
  
  return(
    <Layout>
      <SEO title='Case Study' description={ DESC } />
      <Header>
        <HeaderImg
          src={ Img } 
          alt='banner'
          width='100%'
        />
        <Title>
          <FormattedMessage id='f2a18570a' />
        </Title>
      </Header>
      <InfiniteScroll
        dataLength={ caseStudyList.length }
        next={ () => setPage(page+1) }
        hasMore={ page < totalPage }
        loader={ <h4>Load More...</h4> }
      >
        <CaseStudyListCard caseStudies={ caseStudyList } />
      </InfiniteScroll>
      <CaseStudyListFooter />
    </Layout>
  )
}

export default CaseStudyList
