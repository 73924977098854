import styled from 'styled-components'
import { mykonosBlue } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Container = styled.div`
  height: 230px;
  display: flex;
  background-color: ${mykonosBlue};
  padding: 0 4rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;

  > button {
    font-size: 20px;
    font-weight: 800;
    height: 2.75rem;
  }

  @media only screen and (max-width: 768px) {
    height: 150px;
    margin-top: 1.25rem;
  }

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
    padding: 1rem 1rem 2rem 1rem;
    height: 13rem;

    > button {
      font-size: 1rem;
      font-weight: 800;
      height: 2.5rem;
    }
  }

  ${IS_FRAME_TABLET} {
    flex-direction: column;
    padding: 1rem 1rem 2rem 1rem;
    height: 13rem;

    > button {
      font-size: 1rem;
      font-weight: 800;
      height: 2.5rem;
    }
  }
`

export const TextWrapper = styled.div`
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  flex: 0 050%;

  > h2 {
    font-size: 1.5rem;
  }

  > p {
    font-size: 1.1rem;
    line-height: 1.5;
  }

  ${IS_FRAME_MOBILE} {
    > h2 {
      font-size: 1.1rem;
    }

    > p {
      font-size: 0.9rem;
    }
  }

  ${IS_FRAME_TABLET} {
    > h2 {
      font-size: 1.1rem;
    }

    > p {
      font-size: 0.9rem;
    }
  }
`