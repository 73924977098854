import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Header = styled.div`
  position: relative;
`

export const HeaderImg = styled.img`
  object-fit : cover; 
  height : 25rem;

  ${IS_FRAME_MOBILE} {
    height: 11rem;
    width: 100%;
  }

  ${IS_FRAME_TABLET} {
    height: 11rem;
    width: 100%;
  }
`

export const Title = styled.h1`
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1.33;
  color: #fff;
  margin: 0;
  position: absolute;
  bottom: 4rem;
  left: 4rem;

  ${IS_FRAME_MOBILE} {
    font-size: 1.3rem;
    bottom: 1rem;
    left: 1rem;
  }

  ${IS_FRAME_TABLET} {
    font-size: 1.3rem;
    bottom: 1rem;
    left: 1rem;
  }
`
