import React from 'react'
import { navigate } from 'gatsby'
import { Button } from 'components'
import { Container, TextWrapper } from './styles'
import { FormattedMessage } from 'react-intl'

const CaseStudyListFooter = () => (
  <Container>
    <TextWrapper>
      <h2>
        <FormattedMessage id='a2ec41a60' />
      </h2>
      <p>
        <FormattedMessage id='c75f181a6' />
      </p>
    </TextWrapper>
    <Button onClick={ () => navigate('/register') } type="warning">
      <FormattedMessage id='9b4953594' />
    </Button>
  </Container>
)

export default CaseStudyListFooter
