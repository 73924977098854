import styled from 'styled-components'
import { darkBlueGrey, lightGrey, mykonosBlue } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 4rem;
  grid-gap: 30px;

  ${IS_FRAME_TABLET} {
    margin: 2rem 1.5rem;
  }

  ${IS_FRAME_MOBILE} {
    margin: 2rem 1rem;
    grid-template-columns: 1fr;
  }
`

export const ContentItem = styled.div`
  display: flex;
  cursor: pointer;
  background: #ffffff;

  &:hover {
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }

  > img {
    flex: 0 0 30%;
    width: 5rem;
    height: 10rem;
    position: relative;

    &:before {
      content: " ";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url("https://indonesiapower.co.id/SiteAssets/image-not-found.png");
      background-size: cover;
      background-position: center;
    }
  }

  ${IS_FRAME_TABLET} {    
    > img {
      width: 7rem;
      height: auto;
      max-height: 8rem;
    }
  }

  ${IS_FRAME_MOBILE} {    
    > img {
      width: 5rem;
      height: 5rem;
    }
  }
`

export const ContentDescription = styled.div`
  width: 100%;
  padding: 1.5rem 0 1.5rem 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > a {
    font-size: 1rem;
    text-transform: uppercase;
    position: relative;
    height: -webkit-fill-available;
    color: ${darkBlueGrey};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }

  > div {
    color: ${darkBlueGrey};
  }

  &:hover {
    border-bottom: ${mykonosBlue} 0.4rem solid;
  }

  ${IS_FRAME_TABLET} {

    width: unset;
    padding: 0 0 0 1rem;

    > a {
      font-size: 1rem;
      line-height: 1.5;
    }

    &:hover {
      border-bottom: ${darkBlueGrey} 0.1rem solid;
    }
  }

  ${IS_FRAME_MOBILE} {

    width: unset;
    padding: 0 0 0 1rem;

    > a {
      font-size: 0.9rem;
    }

    &:hover {
      border-bottom: ${darkBlueGrey} 0.1rem solid;
    }
  }
`

export const TypeText = styled.div`
  padding: 0.75rem 0 1rem;

  ${IS_FRAME_MOBILE} {
    padding: 0.5rem 0 0.25rem;
  }
`

export const Button = styled.div`
  color: ${lightGrey} !important;
  padding-top: 1rem;
  font-size: 0.75rem !important;

  ${IS_FRAME_TABLET} {
    margin-top: 0.5rem;
  }

  ${IS_FRAME_MOBILE} {
    margin-bottom: 0.25rem;
  }
`

export const ButtonText = styled.span`
  margin-right: 0.5rem;
  text-transform: none !important;

  ${IS_FRAME_TABLET} {
    font-size: 0.85rem;
  }
`

export const ChevronRight = styled.div`
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  transform: rotate(45deg);
  width: 0.45em;
`
